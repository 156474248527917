<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header class="info--text">
                Configurações - Mapa
                <template v-slot:actions>
                    <v-icon color="info">edit</v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-form ref="form" v-model="valid" @submit.prevent="send">
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-switch v-model="input.enable" label="Mostrar no mapa" class="my-0 py-0" />
                        </v-flex>

                        <v-flex xs12 md11>
                            <v-text-field v-model="input.name" :rules="[rules.required]" label="Nome" class="my-0 py-0" required />
                        </v-flex>

                        <v-flex xs12 md5>
                            <v-text-field v-model="input.cnpj" :rules="[rules.cnpj]" label="CNPJ" v-mask="'##.###.###/####-##'" return-masked-value class="my-0 py-0" />
                        </v-flex>
                        <v-flex xs12 md5 offset-md1>
                            <v-text-field type="tel" v-model="input.contact" :rules="[rules.phone]" label="Contato" class="my-0 py-0" />
                        </v-flex>

                        <v-flex xs12 md5>
                            <v-select v-model="input.workDays" :items="workDays" item-value="key" item-text="description" label="Aberto" class="my-0 py-0" />
                        </v-flex>
                        <v-flex xs12 md2 offset-md1>
                            <v-text-field v-model="input.openOn" :rules="[rules.time]" label="Abre" v-mask="'##:##'" return-masked-value class="my-0 py-0" />
                        </v-flex>
                        <v-flex xs12 md2 offset-md1 class="mb-12">
                            <v-text-field v-model="input.closeOn" :rules="[rules.time]" label="Fecha" v-mask="'##:##'" return-masked-value class="my-0 py-0" />
                        </v-flex>

                        <v-flex xs11>
                            <v-select v-model="input.idCity" :rules="[rules.required]" :items="cityList" item-value="id" item-text="name" label="Cidade" />
                        </v-flex>

                        <v-flex xs12 md5>
                            <v-textarea v-model="input.address" label="Endereço" rows="3" class="my-0 py-0"></v-textarea>
                        </v-flex>
                        <v-flex xs12 md5 offset-md1 class="mb-12">
                            <v-text-field v-model="input.houseNumber" label="Número" type="number" class="my-0 py-0" />
                            <v-text-field v-model="input.cep" :rules="[rules.cep]" label="CEP" v-mask="'#####-###'" return-masked-value class="my-0 py-0" />
                        </v-flex>

                        <v-flex xs12 md5>
                            <v-text-field v-model="input.location" :rules="[rules.required, rules.coordinate]" label="Coordenadas" class="my-0 py-0" required />
                        </v-flex>
                        <v-flex xs12 md5 offset-md1>
                            <v-text-field v-model="input.timezone" :rules="[rules.timezone]" label="Timezone" v-mask="rules.timezoneMask" return-masked-value class="my-0 py-0" />
                        </v-flex>

                        <v-flex xs11>
                            <v-textarea v-model="input.description" label="Descrição" rows="3" class="my-0 py-0"></v-textarea>
                        </v-flex>

                        <v-flex xs12>
                            <v-btn color="primary" @click="send" :loading="sending" style="width: 100%;">Salvar</v-btn>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            rules,
            input: null,
            valid: true,
            sending: false,
            cityList: [],
            workDays: [
                { key: "Everyday", description: "Todos os dias" },
                { key: "Workdays", description: "Segunda a sexta" },
                { key: "Weekend", description: "Finais de semana" },
                { key: "Monday", description: "Segunda" },
                { key: "Tuesday", description: "Terça" },
                { key: "Wednesday", description: "Quarta" },
                { key: "Thursday", description: "Quinta" },
                { key: "Friday", description: "Sexta" },
                { key: "Saturday", description: "Sábado" },
                { key: "Sunday", description: "Domingo" },
            ],
        };
    },

    created() {
        this.input = { ...this.data };
    },

    mounted() {
        this.$http
            .get("api/v2/city")
            .then((result) => {
                this.cityList = result;
            })
            .catch(() => {
                this.cityList = [];
            });
    },

    methods: {
        send() {
            if (this.$refs.form.validate()) {
                this.sending = true;
                this.$http
                    .put("api/v2/point", this.input.id, this.input)
                    .then((result) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Dados salvo com sucesso.");
                    })
                    .catch((error) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao salvar dados.");
                    });
            }
        },
    },
};
</script>