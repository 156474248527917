<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header class="info--text">
                Configurações - Carregador
                <template v-slot:actions>
                    <v-icon color="info">edit</v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-form ref="form" v-model="valid" @submit.prevent="send">
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-switch v-model="input.enable" label="Habilitado" class="my-0 py-0" />
                        </v-flex>
                        <v-flex xs12 md4>
                            <v-text-field class="my-0 py-0" v-model="input.numberPlugs" :rules="[rules.required, (v) => v <= 10 || 'Máximo 10', (v) => v >= 1 || 'Mínimo 1']"
                                label="Número de Plugs" type="number" required />
                        </v-flex>
                        <v-flex xs12 md4>
                            <v-text-field v-model="input.name" :rules="[rules.required, rules.linkName]" label="Nome" class="my-0 py-0" required />
                        </v-flex>
                        <v-flex xs12 sm4>
                            <v-select v-model="input.policy" :items="policies" :rules="[rules.required]" label="Política de acesso" class="my-0 py-0" />
                        </v-flex>
                        <v-flex xs12>
                            <v-btn color="primary" @click="send" :loading="sending" style="width: 100%;">Salvar</v-btn>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import policies from "@/assets/json/policies.json";
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            rules,
            input: null,
            sending: false,
            policies,
        };
    },

    created() {
        this.input = { ...this.data };
    },

    methods: {
        send() {
            if (this.$refs.form.validate()) {
                this.sending = true;
                this.$http
                    .put("api/v2/bike/charger", this.input.id, this.input)
                    .then((result) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Dados salvo com sucesso.");
                    })
                    .catch((error) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao salvar dados.");
                    });
            }
        },
    },
};
</script>