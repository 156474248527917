<template>
    <v-expansion-panels v-model="isExpand">
        <v-expansion-panel>
            <v-expansion-panel-header :class="titleColor">
                {{ title }}
                <template v-slot:actions>
                    <v-icon :color="iconColor">{{ icon }}</v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-alert border="left" colored-border type="info" elevation="5">
                    <v-container grid-list-xl>
                        <v-layout wrap>
                            <v-flex xs12 md6 class="py-0"> <strong>Data Cadastro: </strong>{{ this.charge.createdIn }} </v-flex>
                            <v-flex xs12 md6 lg4 class="py-0" v-if="this.charge.data"><strong>Última comunicação: </strong>{{ this.charge.data.keepAlive }}</v-flex>
                            <v-flex xs12 md6 class="py-0"> <strong>Modelo: </strong>{{ this.charge.model }} </v-flex>
                            <v-flex xs12 md6 class="py-0"> <strong>Identificador: </strong>{{ this.charge.UUID }} </v-flex>
                            <v-flex xs12 md6 class="py-0" v-if="this.charge.board"> <strong>Conexão: </strong>{{ this.charge.board.connection }} </v-flex>
                            <v-flex xs12 md6 class="py-0" v-if="this.charge.board"> <strong>Gateway: </strong>{{ this.charge.board.name }} </v-flex>
                            <v-flex xs12 md6 class="py-0" v-if="this.charge.board"> <strong>Versão: </strong>{{ this.charge.board.version }}</v-flex>
                        </v-layout>
                    </v-container>

                    <Charger :data="this.charge" />
                    <Point v-if="this.charge.point" :data="this.charge.point" />
                    <BikeConfiguration v-if="this.charge.bikeChargerConfiguration" :data="this.charge.bikeChargerConfiguration" />
                </v-alert>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import Point from "@/components/form/Point";
import BikeConfiguration from "@/components/form/BikeConfiguration";
import Charger from "@/components/form/ChargerBasic";
import statusCharger from "@/helpers/StatusCharger";

export default {
    props: {
        charge: {
            type: Object,
            required: true,
        },
    },
    components: {
        Point,
        BikeConfiguration,
        Charger,
    },
    data() {
        return {
            statusCharger,
            isExpand: undefined,
            input: null,
        };
    },
    computed: {
        title() {
            let pointName = this.charge.point ? this.charge.point.name : "";
            let chargerName = this.charge.name ? " '" + this.charge.name + "' " : "";

            let status = "";
            if (this.charge.data.online === 1 && this.charge.car) {
                status = `[${this.statusCharger.getTranslated(this.charge.car.status)}]`;
            }

            return `${pointName} ${chargerName} ${status}`;
        },
        titleColor() {
            return this.charge.data.online === 1 ? "success--text" : this.charge.data.server == 1 ? "warning--text" : "error--text";
        },
        iconColor() {
            return this.charge.data.online === 1 ? "success" : this.charge.data.server == 1 ? "warning" : "error";
        },
        icon() {
            return this.charge.data.online === 1 ? "done" : this.charge.data.server == 1 ? "warning" : "error";
        },
    },
};
</script>