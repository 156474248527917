<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header class="info--text">
                Configurações - eBike
                <template v-slot:actions>
                    <v-icon color="info">edit</v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-form ref="form" v-model="valid" @submit.prevent="send">
                    <v-layout wrap>
                        <v-flex xs12 md3 offset-md1>
                            <v-text-field v-model="input.time" :rules="[rules.required, (v) => v <= 420 || 'Máximo 420', (v) => v >= 1 || 'Mínimo 1']" label="Tempo"
                                suffix="minutos" type="number" required />
                        </v-flex>
                        <v-flex xs12 md3 offset-md1>
                            <v-text-field v-model="input.price" :rules="[rules.money]" label="Preço" v-mask="['#.##', '##.##']" return-masked-value prefix="R$" type="number"
                                required />
                        </v-flex>
                        <v-flex xs12>
                            <v-btn color="primary" @click="send" :loading="sending" style="width: 100%;">Salvar</v-btn>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            rules,
            input: null,
            sending: false,
        };
    },

    created() {
        this.input = { ...this.data };
    },

    methods: {
        send() {
            if (this.$refs.form.validate()) {
                this.sending = true;
                this.$http
                    .put("api/v2/bike/configuration", this.input.id, this.input)
                    .then((result) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Dados salvo com sucesso.");
                    })
                    .catch((error) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao salvar dados.");
                    });
            }
        },
    },
};
</script>